import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators';
import {until} from 'lit/directives/until';
import '@iconify/iconify';
import { DBDeparture, DBDepaturesResponse} from '../apis/DBDepartures';
import { DateTime } from 'luxon';

@customElement('db-depatures')
export class DbDepaturesElement extends LitElement {
  render() {
    return html` ${until(this.getDepartures(), html`?`)} `;
  }

  createRenderRoot() {
    return this;
  }

  async getDepartures() {
    const { departures }: DBDepaturesResponse = await fetch(
      'https://dbf.finalrewind.org/Hamburg%20Hbf?rt=1&via=Berlin+Hbf&mode=json&version=3&limit=4'
    ).then((res) => res.json());

    const selectedDepartures = departures
      .filter(dep => !dep.train.includes("FLX"))

    if (selectedDepartures.length === 0) {
      return html`<div class=" font-bold text-xl">Zurzeit keine Abfahrten nach Berlin</div>`
    }

    return html`<div class="grid gap-4 font-bold text-xl" style="grid-template-columns: max-content max-content auto max-content;">
      ${[...selectedDepartures.map((departure) => renderDepature(departure))]}
    </div>`;
  }
}

function renderDepature(departure: DBDeparture) {
  return html`
      <div class="text-left font-mono ${departure.delayDeparture > 0 ? 'text-red' : ''} ${departure.isCancelled ? 'decoration-solid decoration-2' : ''}">
        ${DateTime.fromFormat(departure.scheduledDeparture, 'HH:mm').plus({minutes: departure.delayDeparture}).toFormat('HH:mm')}
        <span class="text-red text-sm">${departure.delayDeparture ? ` (+${departure.delayDeparture})` : ''}</span>
      </div>
      <div class="text-center">
        <div class="rounded-3xl px-2 text-white bg-red">
          ${departure.train}
        </div>
      </div>
      <div class="">${departure.destination.replace(/ HBF/i, '').replace(/ \(tief\)/i, '')}</div>
      <div class="">${departure.platform}</div>
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    'db-depatures': DbDepaturesElement;
  }
}
